import forge from 'node-forge';
import secureStorage from './encrypt';
import { MasterService } from '../services';

export const encryptData = async (body, URL) => {
    try {
        let encPubKey = secureStorage.getItem("public_key") || await MasterService.getPublicKeySFA()
        let symmetricKey = forge.random.getBytesSync(16);
        let iv = forge.random.getBytesSync(16);
        var encryptedReqData;
        let encryptedSymmetricKey;
        let isBodyLength = false;
        let formObj = {};
        let updatedFormData = new FormData();

        if (URL.indexOf('/addDocumentWeb') >= 0 || URL.indexOf('/uploadCsv') >= 0 || URL.indexOf('/uploadAddOnCsv') >= 0 || URL.indexOf('/addInsurancePartner') >= 0 || URL.indexOf('/updateInsurancePartner') >= 0 || URL.indexOf('/addAgencyWebDocument') >= 0 || URL.indexOf('/paymentProofDocs') >= 0 || URL.indexOf('/uploadAndReadDocument') >= 0) {
            for (var pair of body.entries()) {
                if (pair[0] !== "files") {
                    formObj[pair[0]] = pair[1]
                } else {
                    updatedFormData.append('files', pair[1])
                }
            }
            isBodyLength = true;
        } else {
            isBodyLength = (body && Object.keys(body)?.length);
        }
        if (isBodyLength) {
            let cipher = forge.cipher.createCipher('AES-CBC', symmetricKey);
            cipher.start({ iv: iv });
            cipher.update(forge.util.createBuffer(JSON.stringify(body), 'utf8'));
            cipher.finish();
            encryptedReqData = forge.util.encode64(cipher.output.getBytes());

            if (Object.keys(formObj).length) {
                updatedFormData.append('formData', encryptedReqData)
                encryptedReqData = body

            }
            const publicKey = forge.pki.publicKeyFromPem(encPubKey);
            encryptedSymmetricKey = publicKey.encrypt(symmetricKey, 'RSA-OAEP');
            encryptedSymmetricKey = forge.util.encode64(encryptedSymmetricKey);
        }
        return { encryptedReqData, iv: forge.util.encode64(iv), symmetricKey: encryptedSymmetricKey };
    } catch (error) {
        throw error;
    }
};

export const DecryptData = (params) => {
    try {
        let { encryptedData, iv, symmetricKey } = params
        const decipher = forge.cipher.createDecipher('AES-CBC', forge.util.decode64(symmetricKey));
        decipher.start({ iv: forge.util.decode64(iv) });
        decipher.update(forge.util.createBuffer(forge.util.decode64(encryptedData), 'raw'));
        decipher.finish();
        return JSON.parse(decipher.output.toString());
    } catch (err) {
        throw new Error('Unable to decrypt data');
    }
}