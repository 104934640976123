/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import Loader from "../common/loader";
import DatePicker from "react-datepicker";
import Select, { components } from "react-select";
import ReactPaginate from "react-paginate";
import InputField from "../common/Input";
import { Tab, Nav } from "react-bootstrap";
import { MasterService } from "../../services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import secureStorage from "../../config/encrypt";

const { ValueContainer, Placeholder } = components;

const AdminUserManagement = () => {
    const allUserLink = useRef();
    const deletedUserLink = useRef();
    const [filterOptions, setFilterOptions] = useState({});
    const [userList, setUserList] = useState([]);
    const [adminRole, setAdminRole] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [pageCount, setPageCount] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [activeCount, setActiveCount] = useState(0);
    const [deletedCount, setDeletedCount] = useState(0);
    const [currentStatus, setCurrentStatus] = useState("1");
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loginUserInfo] = useState(secureStorage.getItem("loginUserInfo"));

    let historyLead = useNavigate();

    useEffect(() => {
        getAdminRoleList();
        getAdminUserList({ status: currentStatus });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Fetching Data
    const getAdminUserList = (params) => {
        setLoading(true);
        MasterService.post("/sfa/admin/get_admin_user_list", params)
            .then((response) => {
                if (response && response.data && response.data.status === 200) {
                    let responseData = response.data.data.data;
                    let totalCount = response.data.data.total;
                    let perPage = response.data.data.perPage;
                    let activeCount = response.data.data.active;
                    let deletedCount = response.data.data.deleted;
                    setLoading(false);
                    setUserList(responseData);
                    setPageCount(Math.ceil(totalCount / perPage));
                    setTotalCount(totalCount);
                    setActiveCount(activeCount);
                    setDeletedCount(deletedCount);
                } else {
                    setLoading(false);
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error);
            });
    };

    const getAdminRoleList = async () => {
        setLoading(true);
        try {
            let response = await MasterService.post("/sfa/admin/get_admin_role_list", {});
            if (response && response.data && response.data.status === 200) {
                let responseData = response.data.data;
                setAdminRole(responseData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };

    // Handling Event
    const handleTabChange = (statusId) => {
        setCurrentStatus(statusId);
        setFilterOptions({});
        setDateRange([null, null]);
        getAdminUserList({ status: statusId });
    };

    const handleChange = (event, name) => {
        let tempFilterOptions = { ...filterOptions };
        if (name === "admin_role") {
            tempFilterOptions["admin_role"] = event.id;
        } else if (name === "email") {
            tempFilterOptions["email"] = event.target.value;
        } else if (name === "created_date") {
            setDateRange(event);
            if (event !== null) {
                if (event[0]) {
                    tempFilterOptions["start_created_date"] = event[0];
                }
                if (event[1]) {
                    tempFilterOptions["end_created_date"] = event[1];
                }
            }
        }
        setFilterOptions(tempFilterOptions);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        getAdminUserList({ ...filterOptions, status: currentStatus });
    };

    const handleSearchBy = (event, name) => {
        event.preventDefault();
        let tempFilterOptions = { ...filterOptions };

        if (name === "search_by") {
            if (!event.target.value) {
                delete tempFilterOptions["search_by"];
            } else {
                tempFilterOptions["search_by"] = event.target.value;
            }
        }

        setFilterOptions(tempFilterOptions);
        getAdminUserList({ ...tempFilterOptions, status: currentStatus });
    };

    const handleAddAdmin = (event) => {
        historyLead("/add-admin-user/admin_basic_details");
    };

    const handleAdminStatus = async (adminId) => {
        setLoading(true);
        try {
            const response = await MasterService.post("/sfa/admin/change_admin_status", { admin_id: adminId, status: "0", deleted_by: loginUserInfo.id });
            if (response && response.data && response.data.status) {
                toast.success(response.data.data);
                getAdminUserList({ status: currentStatus });
                setOffset(0);
                setLoading(false);
            } else {
                toast.error(response.data.message);
                setLoading(false);
            }
        } catch (error) {
            toast.error(error);
        }
    };

    // Helper functions
    const resetFilter = () => {
        setFilterOptions({});
        setDateRange([null, null]);
        getAdminUserList({ status: currentStatus });
    };

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setOffset(selectedPage);
        getAdminUserList({ ...filterOptions, page: selectedPage + 1, status: currentStatus });
    };

    const handleAdminView = (adminId) => {
        historyLead(`/view-admin-user/${adminId}`);
        // window.open(`/view-admin-user/${adminId}`)
    };

    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <div className="container-fluid">
                <div className="top-heading d-flex">
                    <h1>Sales Support User Management</h1>
                    <div className="right-btn">
                        <button
                            className="btn-line p-lg"
                            onClick={(e) => {
                                handleAddAdmin(e);
                            }}
                        >
                            Add Sales Support User
                        </button>
                    </div>
                </div>
                <div className="loan-filter-bg">
                    <div className="top-filter-bg">
                        <React.Fragment>
                            <div className="lead-list-filter">
                                <fieldset className="rangepicker-calendra">
                                    <div className="material">
                                        <div className="datepicker">
                                            <DatePicker
                                                name="created_date"
                                                id="created_date"
                                                selectsRange={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                dateFormat="yyyy-MM-dd"
                                                onChange={(e) => {
                                                    handleChange(e, "created_date");
                                                }}
                                                isClearable={false}
                                                placeholderText={!startDate ? `${currentStatus === "1" ? "Created" : "Deletd"} Date` : ""}
                                                autoComplete="off"
                                                maxDate={new Date()}
                                            />
                                            {startDate ? (
                                                <label
                                                    data-label={currentStatus === "1" ? "Created Date" : "Deleted Date"}
                                                    className="form-label date-label"
                                                ></label>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset className="single-select">
                                    <div className="material">
                                        <Select
                                            components={{ ValueContainer: CustomValueContainer }}
                                            id="admin_role"
                                            name="admin_role"
                                            onChange={(e) => {
                                                handleChange(e, "admin_role");
                                            }}
                                            options={adminRole}
                                            value={adminRole.filter((item) => filterOptions.admin_role === item.id)}
                                            placeholder="Role"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            getOptionValue={({ id }) => id}
                                            getOptionLabel={({ label }) => label}
                                        />
                                    </div>
                                </fieldset>

                                <fieldset className="admin-email">
                                    <div className="material">
                                        <InputField
                                            id="email"
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            autoComplete="off"
                                            value={filterOptions && filterOptions.email ? filterOptions.email : ""}
                                            label="Email"
                                            onChange={(e) => {
                                                handleChange(e, "email");
                                            }}
                                        />
                                    </div>
                                </fieldset>
                                <div className="btn-submit-reset m-sm-l">
                                    <button
                                        onClick={(e) => {
                                            handleSubmit(e);
                                        }}
                                        type="button"
                                        className="btn-primary"
                                    >
                                        Search
                                    </button>
                                    <button
                                        onClick={(e) => {
                                            resetFilter(e);
                                        }}
                                        className="btn-reset"
                                    >
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </React.Fragment>
                    </div>

                    <div className="user-management-detail-tab-outer">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <div className="user-managemnt-detail-tabs">
                                <Nav variant="pills" className="flex-column tab-line">
                                    <div className="tab-list">
                                        <Nav.Item onClick={() => handleTabChange("1")} ref={allUserLink}>
                                            <Nav.Link eventKey="first">
                                                All Users ({currentStatus && currentStatus === "1" ? totalCount : activeCount})
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onClick={() => handleTabChange("0")}>
                                            <Nav.Link eventKey="second" ref={deletedUserLink}>
                                                Deleted Users ({currentStatus && currentStatus === "0" ? totalCount : deletedCount})
                                            </Nav.Link>
                                        </Nav.Item>
                                    </div>
                                </Nav>
                                <fieldset className="search-bx">
                                    <div className="material">
                                        <InputField
                                            id="search_by"
                                            type="text"
                                            placeholder="Search By Name"
                                            name="search_by"
                                            autoComplete="off"
                                            value={filterOptions && filterOptions.search_by ? filterOptions.search_by : ""}
                                            label="Search"
                                            onChange={(e) => {
                                                handleSearchBy(e, "search_by");
                                            }}
                                        />
                                        <i className="ic-search"></i>
                                    </div>
                                </fieldset>
                            </div>
                            <div className="admin-table user-managemnt-detail-table data-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Role</th>
                                            <th>{currentStatus && currentStatus === "1" ? `Created Date` : `Deleted Date`}</th>
                                            <th>{currentStatus && currentStatus === "1" ? `Created By` : `Deleted By`}</th>
                                            {currentStatus && currentStatus === "1" ? <th>Actions</th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userList && userList.length ? (
                                            userList.map((item, idx) => (
                                                <tr key={idx}>
                                                    <td>{item.display_name}</td>
                                                    <td>{item.username}</td>
                                                    <td>{item.role_name}</td>
                                                    <td>{item.date}</td>
                                                    <td>{item.by_user}</td>
                                                    {currentStatus && currentStatus === "1" ? (
                                                        <td>
                                                            <ul className="action-btn">
                                                                <li>
                                                                    <a
                                                                        className="btn btn-default"
                                                                        onClick={() => {
                                                                            handleAdminView(item.admin_id_hash);
                                                                        }}
                                                                    >
                                                                        <i className="ic-edit"></i>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a
                                                                        className="btn btn-default"
                                                                        onClick={() => {
                                                                            handleAdminStatus(item.admin_id_hash);
                                                                        }}
                                                                    >
                                                                        <i className="ic-delete"></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    ) : null}
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={currentStatus && currentStatus === "1" ? "6" : "5"} style={{ textAlign: "left" }}>
                                                    No records found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <div className="pagination-bx" id="dealerList">
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        forcePage={offset}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
        </ValueContainer>
    );
};

export default AdminUserManagement;
