import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Notifaction from "./Notification";
import logo_img from "../../webroot/images/logo.svg";
import secureStorage from "../../config/encrypt";
import { useTranslation } from "react-i18next";
const Navigation = (props) => {
    const { t, i18n } = useTranslation("common");
    let language = secureStorage.getItem("lang") === "id" ? "EN" : "EN";
    let logged_data = localStorage.getItem("logged_data") ? JSON.parse(localStorage.getItem("logged_data")) : {};
    let display_name = logged_data.display_name ? logged_data.display_name : "";
    return (
        <div className="container-fluid">
            <div className="header-main">
                <div className="logo">
                    <img src={logo_img} className="" alt="SFA" />
                </div>
                <div className="right-panel-naviganion menupanel">
                    <div className="nav">
                        <ul>
                            <li>
                                <NavLink to="/">User Management</NavLink>
                            </li>
                            <li>
                                <NavLink to="/map-dealer">Map Dealer/Executive</NavLink>
                            </li>
                            <li>
                                <NavLink to="/dealer-user-listing">Dealer/Executive Mapping</NavLink>
                            </li>
                            <li className="dropdownmenu">
                                <NavLink to="#">
                                    <div className="username">
                                        <span>Reports</span>
                                    </div>
                                </NavLink>
                                <ul className="submenu">
                                    <li>
                                        <NavLink to="/view-report">Visit Reports</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/no-view-report">No Visit Reports</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/attendence-report">Attendance Report</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/role-history-report">Role History Report</NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdownmenu">
                                <NavLink to="#">
                                    <div className="username">
                                        <span>More</span>
                                    </div>
                                </NavLink>
                                <ul className="submenu">
                                    <li>
                                        <NavLink to="/role-management">Role Management</NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink to="/admin-user-management">Sales support user management</NavLink>{" "}
                                    </li> */}
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <a className="notification-btn">
                        <Notifaction />
                    </a>

                    <div className="user-detail ">
                        <ul>
                            <li className="dropdownmenu">
                                <NavLink to="#">
                                    <span className="userarc">T</span>
                                    <div className="username">
                                        <span>Admin</span>
                                        <span className="user-degintion">{display_name}</span>
                                    </div>
                                </NavLink>
                                <ul className="submenu">
                                    <li>
                                        <NavLink to="/logout">Logout</NavLink>{" "}
                                    </li>
                                    <li>
                                        <NavLink to="/office_address">Office Address</NavLink>{" "}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <div className="language">
                        <ul>
                            <li className="dropdownmenu">
                                <NavLink to="#">{language}</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Navigation;
