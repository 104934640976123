/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import { API_URL, APIV } from './../config/constant';
import secureStorage from './../config/encrypt';
import { toast } from 'react-toastify';
import ls from "local-storage";
import { DecryptData, encryptData } from '../config/req_encrypt';

const Axios = axios.create();

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

Axios.interceptors.response.use(
    (response) => {
        if (response.data && response?.status !== 200) {
            console.error(`${response?.status} - ${response.data.message}`, response.data)
            // if(response.data.status == 400
            //     window.commonErrorToast(response.data.message)
            //     toast.error(`${response.data.status} - ${response.data.message}`);
        }
        ls.set("is_redirect", 1);
        return response;
    },
    async (error) => {
        console.error(`${error.response.status} - ${error.response.statusText}`, error.response);

        // Original request configuration.
        const originalRequest = error?.config;

        if (originalRequest.url === `${API_URL.GETWAY_API}sfa/user/refresh_token`) {
            // This is a failed refresh token request. Logout user.
            // window.location.href = '/logout';
            logout()
            return Promise.reject(error);
        }
        
        // Check if the error response exists and has a status of 401 (Unauthorized).
        if (error.response && error.response.status === 401) {

            // If a token refresh is already in process.
            if (isRefreshing) {
                // Create a new promise and push it into the failedQueue along with the original request.
                // We use the original request to later retry this failed request.
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject, originalRequest });
                })
                    .then((token) => {
                        // Once the token is refreshed, update the Authorization header.
                        originalRequest.headers["Authorization"] = token;
                        // Retry the original request.
                        return Axios(originalRequest);
                    })
                    .catch((err) => {
                        // If token refresh fails, reject the promise.
                        return Promise.reject(err);
                    });
            }

            // Flag to indicate that this request has been retried.
            originalRequest._retry = true;

            // Flag to indicate a token refresh is in progress.
            isRefreshing = true;

            // Retrieve the redirect status.
            let is_redirect = ls.get("is_redirect");

            // Object to store the new refresh token.
            let refresh_token_data = {};

            // If a redirect is not in progress, execute this block.
            if (is_redirect !== 0) {
                try {
                    // Attempt to refresh the token.
                    refresh_token_data = await refreshToken();

                    // Process the queue of failed requests.
                    processQueue(null, refresh_token_data.token);

                    // Update the Authorization header with the new token.
                    originalRequest.headers["Authorization"] = refresh_token_data.token;

                    // Retry the original request with the new token.
                    return Axios(originalRequest);

                } catch (err) {
                    // If token refresh fails, clear the queue and redirect to logout.
                    processQueue(err, null);
                    // window.location.href = "/logout";
                    logout()
                    return Promise.reject(err);
                } finally {
                    // Reset the flag to indicate that the token refresh has completed.
                    isRefreshing = false;
                }
            }
        }
        // If the status code is not 401, return the error response as is.
        return error.response;
    });


window.commonErrorToast = function (error) {
    if (typeof error == "string") toast.error(error);
    else if (error.message) toast.error("Helllo", error.message);
}

async function refreshToken() {
    try {
        let loginUserInfo = (secureStorage.getItem('loginUserInfo'))
            ? secureStorage.getItem('loginUserInfo')
            : null;
        if (loginUserInfo) {
            const { data } = await Axios.post(`${API_URL.GETWAY_API}sfa/user/refresh_token`, { refresh_token: loginUserInfo.refresh_token }, {
                headers: { 'Authorization': loginUserInfo.token }
            });
            if (data && data.status === 200) {
                ls.set('logged_data', data.data);
                ls.set('is_redirect', 0)
                secureStorage.setItem('loginUserInfo', data.data);
                return data.data;
            } else {
                // window.location.href = '/logout';
                logout()
            }
        } else {
            // window.location.href = '/logout';
            logout()
        }

    } catch (error) {
        console.error("Error refreshing token: ", error);
        // window.location.href = '/logout';
        logout()
        return null;
    }
}

function logout() {
    ls.set('logged_user_id', 0);
    ls.set('logged_data', {});
    ls.set('login_email', "");
    secureStorage.removeItem('loginUserInfo');
    let path = window?.navigator?.userAgentData?.platform == "Windows" ? "/" : "/user-management"
    window.location.href = path
}

export default {
    getMasters(list) {
        return this.get("/core/commonservice/master", { master: list });
    },
    getToken() {
        let fetchHeader = (secureStorage.getItem('loginUserInfo'))
            ? secureStorage.getItem('loginUserInfo')
            : {};
           // console.log('$$$$$$$$$$$$$$',fetchHeader.token);
        return (fetchHeader && fetchHeader.token)
            ? fetchHeader.token : null;
    },
    delete(URL, body, headers) {
        headers = headers || {};
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return Axios.delete(`${API_URL.GETWAY_API}${URL}`, body, { headers })
    },
    async post(URL, body, headers) {
        try {
            headers = headers || {};
            if (APIV) headers['apiv'] = APIV;
            headers['Authorization'] = this.getToken();
            headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
            URL = (URL[0] === '/') ? URL.substr(1) : URL;
            if (body && URL != "sfa/user/login-by-email" && URL != 'sfa/user/loan_approved_doc') {
                let encrypt_data = await encryptData(body, URL)
                body.requestData = encrypt_data.encryptedReqData
                headers["encryptedSymmetricKey"] = encrypt_data.symmetricKey
                headers["encryptedIV"] = encrypt_data.iv
            }
            let response = await Axios.post(`${API_URL.GETWAY_API}${URL}`, body, { headers, withCredentials: false })
            if (response?.data?.encryptedData) {
                let decrypt_response = await DecryptData(response.data)
                response.data = decrypt_response
            }
            return response
        } catch (error) {
        }
    },
    put(URL, body, headers) {
        headers = headers || {};
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return Axios.put(`${API_URL.GETWAY_API}${URL}`, body, { headers })
    },
    async get(URL, query, headers) {
        try {
            headers = headers || {};
            if (APIV) headers['apiv'] = APIV;
            headers['Authorization'] = this.getToken();
            headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
            //headers['apikey'] = API_URL.API_KEY;
            URL = (URL[0] === '/') ? URL.substr(1) : URL;
            let response = await Axios.get(`${API_URL.GETWAY_API}${URL}`,
                { params: query, headers })
            if (response?.data?.encryptedData) {
                let decrypt_response = await DecryptData(response.data)
                response.data = decrypt_response
            }
            return response
        } catch (error) {
        }
    },
    uploadFilesToS3(postdata, headers) {
        headers = headers || {};
        return axios.post(
            `${API_URL.COMMON_GATEWAY_API}core/commonservice/docs_upload`,
            postdata,
            { headers }
        );
    },
    getLocalityList(params, headers) {
        headers = headers || {};
        return axios.get(
            `${API_URL.COMMON_GATEWAY_API}core/commonservice/locality_list?city=`+params,
            { headers }
        );
    },   
    download(URL, body, responseType,  headers) {
        headers = headers || {};       
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        //headers['apikey'] = API_URL.API_KEY;        
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return Axios.post(`${API_URL.GETWAY_API}${URL}`,  body, {responseType, headers,withCredentials:false })
    },
    async postCommon(URL, body, headers) {
        headers = headers || {};       
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        //headers['apikey'] = API_URL.API_KEY;        
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        if (body) {
            let encrypt_data = await encryptData(body, URL)
            body.requestData = encrypt_data.encryptedReqData
            headers["encryptedSymmetricKey"] = encrypt_data.symmetricKey
            headers["encryptedIV"] = encrypt_data.iv
        }
        let response = await Axios.post(`${API_URL.SFA_COMMON_API}sfa-common/${URL}`, body, { headers,withCredentials:false })
        // let decrypt_response = await DecryptData(response.data)
        // response.data = decrypt_response
        return response
    },
    async getPublicKeySFA() {
        let res = await axios.get(`${API_URL.GETWAY_API}/getPublicKey`,
            { headers: {} }
        );
        if (res?.data?.data) {
            secureStorage.setItem("public_key", res.data.data)
        }
        return ""
    },
}