/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { MasterService } from "../../services";
import Loader from "../common/loader";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const ViewAdminUser = () => {
    let { admin_id } = useParams();
    const historyLead = useNavigate();

    const [loading, setLoading] = useState(false);
    const [adminDetails, setAdminDetails] = useState({});

    useEffect(() => {
        getAdminDetails();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Fetching data
    const getAdminDetails = async () => {
        setLoading(true);
        try {
            const response = await MasterService.post("/sfa/admin/get_admin_details", { admin_id: admin_id });
            if (response && response.data && response.data.status) {
                let [responseData] = response.data.data;
                setAdminDetails(responseData);
                setLoading(false);
            } else {
                toast.error(response.data.message);
                setLoading(false);
            }
        } catch (error) {
            toast.error(error);
            setLoading(false);
        }
    };

    // Handle events
    const handleEdit = (path) => {
        historyLead(path, admin_id);
    };

    // const handleResetPassword = async () => {
    //     setLoading(true);
    //     try {
    //         const response = await MasterService.post("/sfa/admin/admin_password_reset", { admin_id: admin_id });
    //         if (response && response.data && response.data.status) {
    //             let responseData = response.data.data;
    //             getAdminDetails();
    //             setAdminDetails(responseData);
    //             setLoading(false);
    //             toast.success(responseData);
    //         } else {
    //             toast.error(response.data.message);
    //             setLoading(false);
    //         }
    //     } catch (error) {
    //         toast.error(error);
    //         setLoading(false);
    //     }
    // };

    return (
        <React.Fragment>
            <div className="container-fluid">
                {loading ? <Loader /> : null}

                <div className="agent-detail-tads">
                    <div className="preview-confirmation add-user-detail-filed">
                        <div className="top-heading d-flex">
                            <h2>Basic Details</h2>
                            <a onClick={() => handleEdit("/edit-admin-user/admin_basic_details?id=" + admin_id)} className="edit-opt">
                                Edit
                            </a>
                        </div>

                        <div className="d-flex">
                            <div className="role-detail-preview preview-basic-details">
                                <h3>Personal Detail</h3>
                                <ul>
                                    <li>
                                        <span>Name</span>
                                        <label>{adminDetails.name ? adminDetails.name : "-"}</label>
                                    </li>
                                    <li>
                                        <span>Mobile</span>
                                        <label style={{ display: "flex" }}>{adminDetails.mobile ? adminDetails.mobile : "-"}</label>
                                    </li>
                                    <li>
                                        <span>Email</span>
                                        <label title={adminDetails.email ? adminDetails.email : "-"}>{adminDetails.email ? adminDetails.email : "-"}</label>
                                    </li>

                                    <li>
                                        <span>Access Type</span>
                                        <label>{adminDetails && adminDetails.role_name ? adminDetails.role_name : "-"}</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="d-flex">
                            <div className="role-detail-preview">
                                <h3>Password</h3>
                                <ul>
                                    <li>
                                        <span>Password&nbsp;&nbsp;</span>
                                        <label>
                                            {"********"}
                                            <a className="reset-password m-lg-l" onClick={handleResetPassword}>
                                                Reset Password
                                            </a>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ViewAdminUser;
