import MasterService from "./MasterService";

const InsuranceBoxService = {
    ...MasterService,
    getAgencyInsuranceStatus(postdata) {
        return this.post(`insurancebox/lead/getAgencyInsuranceStatus`, postdata);
    },
    getUserPendingInsuranceApplication(postdata){
        return this.post('insurancebox/lead/users_lead_status',postdata)
    },
};

export default InsuranceBoxService;
